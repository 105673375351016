import { Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import React from "react"
import { Nav, NavItem, NavLink } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"

const Menu = (  ) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    

  return (
    <div className="container">
      <div className="container mt-5 mb-3 ">
        <div className="row justify-content-center">
          <div className="col-6 col-sm-4 col-md-3">
            <Link to={"/"} className="nav-link title-m">
              <Img fluid={data.logo.childImageSharp.fluid} className="" />
            </Link>
          </div>
        </div>
        
        <div className="row justify-content-center mt-3 menu">
          <ul className="nav">
            <Link to={"/"} className="nav-link title-m">
              หน้าหลัก
            </Link>
            <Link to={"/news"} className="nav-link title-m">
               ข่าวสาร
            </Link>
            <Link to={"/videos"} className="nav-link title-m">
               วีดีโอ
            </Link>
            <Link to={"/about"} className="nav-link title-m">
              เกี่ยวกับเรา
            </Link>
            <Link to={"/contact"} className="nav-link title-m">
              ติดต่อเรา
            </Link>
          </ul>
        </div>
        
      </div>
    </div>
  )
}

export default Menu
