import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  FaPhone,
  FaMapMarkerAlt,
  FaFacebookF,
  FaInstagram,
  FaLine,
} from "react-icons/fa"

const Header = ({}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            mobile
            hotline
            facebook
            instagram
            line
          }
        }
      }
    `
  )

  return (
    <header>
      <div className="bg-dark text-light ">
        <div className="container">
          <div className="row">
            <div className="col-7 text-left">
              <ul className="list-inline m-0 pt-1 pb-1">
                <li className="list-inline-item">
                  <a target="_blank" href={`tel:${site.siteMetadata.mobile}`}>
                    <small>
                      <FaPhone /> {site.siteMetadata.mobile}
                    </small>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-5 text-right">
              <ul className="list-inline m-0 pt-1 pb-1">
                <li className="list-inline-item">
                  <a
                    target="_blank"
                    href={`https://line.me/R/ti/p/${site.siteMetadata.line}`}
                  >
                    <small>
                      <FaLine /> {site.siteMetadata.line}
                    </small>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
