import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  FaPhone,
  FaMapMarkerAlt,
  FaFacebookF,
  FaInstagram,
  FaLine,
  FaFacebookMessenger,
} from "react-icons/fa"

const Footer = ({}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            mobile
            hotline
            google_map
            address
            facebook
            instagram
            line
          }
        }
      }
    `
  )

  return (
    <footer>
      <div className="mt-5 mb-5">&nbsp;</div>
      <div className="container">
        <div className="mt-5 mb-5">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">สอบถามและปรึกษาได้ทาง</h2>
              <div className="line-center "></div>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <a
                    target="_blank"
                    href={`tel:${site.siteMetadata.mobile}`}
                    className="btn btn-outline-secondary btn-block btn-lg title"
                  >
                    <FaPhone /> โทร {site.siteMetadata.mobile}
                  </a>
                </div>
                <div className="col-md-4 mt-3">
                  <a
                    target="_blank"
                    href={`https://www.m.me/${site.siteMetadata.facebook}`}
                    className="btn btn-outline-secondary btn-block btn-lg title"
                  >
                    <FaFacebookMessenger /> Facebook
                  </a>
                </div>
                <div className="col-md-4 mt-3">
                  <a
                    target="_blank"
                    href={`https://line.me/R/ti/p/${site.siteMetadata.line}`}
                    className="btn btn-outline-secondary btn-block btn-lg title"
                  >
                    <FaLine /> Line
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-5">&nbsp;</div>
      <div className="bg-dark text-light pt-5 pb-5">
        {site.siteMetadata.google_map && (
          <div className="container-fluid">
            <div className="row  mb-5">
              <div className="col-12">
                <h4 className="text-center">แผนที่ร้าน</h4>
                <div className="line-center"></div>
                <iframe
                  src={site.siteMetadata.google_map}
                  width="100%"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        )}
        <div className="container ">
          <div className="row">
            <div className="col-12 col-sm-3 mt-3">
              <h4>ติดต่อเรา</h4>
              <div className="line"></div>
              <ul className="list-unstyled">
                <li className="mb-2 title">
                  <a target="_blank" href={`tel:${site.siteMetadata.mobile}`}>
                    <FaPhone /> {site.siteMetadata.mobile},{" "}
                    {site.siteMetadata.hotline}
                  </a>
                </li>
                {site.siteMetadata.facebook && (
                  <li className="mb-2 title">
                    <a
                      target="_blank"
                      href={`https://www.m.me/${site.siteMetadata.facebook}`}
                    >
                      <FaFacebookF /> {site.siteMetadata.facebook}
                    </a>
                  </li>
                )}
                {site.siteMetadata.instagram && (
                  <li className="mb-2 title">
                    <a
                      target="_blank"
                      href={`https://www.instagram.com/${site.siteMetadata.instagram}`}
                    >
                      <FaInstagram /> {site.siteMetadata.instagram}
                    </a>
                  </li>
                )}
                {site.siteMetadata.line && (
                  <li className="mb-2 title">
                    <a
                      target="_blank"
                      href={`https://line.me/R/ti/p/${site.siteMetadata.line}`}
                    >
                      <FaLine /> {site.siteMetadata.line}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            {site.siteMetadata.address && (
              <div className="col-12 col-sm-4 mt-3">
                <h4>ที่อยู่</h4>
                <div className="line"></div>
                <ul className="list-unstyled">
                  <li className="title">
                    <FaMapMarkerAlt /> {site.siteMetadata.address}
                  </li>
                </ul>
              </div>
            )}

            <div className="col-12 col-sm-5 mt-3">
              <iframe
                src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${site.siteMetadata.facebook}%2F&tabs&width=340&height=214&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true`}
                width="100%"
                height="214"
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright title container-fluid text-center pt-3 pb-3  ">
        Copyright © {new Date().getFullYear()} {site.siteMetadata.author} All
        Rights Reserved. Powered by{" "}
        <a target="_blank" href="http://olanlab.com">
          OlanLab Co.,Ltd.
        </a>
      </div>
    </footer>
  )
}

export default Footer
